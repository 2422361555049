import { BrowserRouter } from "react-router-dom";
import { HashLink as Link } from "react-router-hash-link";
import about1 from "../assets/About1.png";
import about2 from "../assets/About2.png";
import arrowRight from "../assets/arrow-right.svg";
import blur from "../assets/blur.png";
import doubleArrow from "../assets/double-arrow-down.svg";
import doubleEllipse from "../assets/double-ellipse.svg";
import hamburger from "../assets/hamburger.svg";
import logoWhite from "../assets/logo-white.svg";
import logo from "../assets/logo.svg";
import netWhite from "../assets/net-white.png";
import net from "../assets/net.png";
import uo1 from "../assets/UO1.png";
import uo2 from "../assets/UO2.png";

function Home() {
  return (
    <>
      <BrowserRouter>
        <div className="bg-[#000]" id="home">
          <div className="bg-gradient-to-r from-[#000] via-[#7247FF4D] to-[#000]">
            <div className="w-4/5 mx-auto pb-10 ">
              <div className="flex justify-between py-14">
                <img src={logoWhite} className="w-48" alt="" />
                <nav className="hidden md:block">
                  <ul className="flex gap-10 text-white ">
                    <Link smooth to="#home">
                      Home
                    </Link>
                    <Link smooth to="#about">
                      About
                    </Link>
                    <Link smooth to="#contact">
                      Contact
                    </Link>
                  </ul>
                </nav>
                <img src={hamburger} className="md:hidden" alt="" />
              </div>
              <div className="relative w-11/12 mx-auto py-48">
                <h1 className="bg-gradient-to-r from-[#FFFFFF] to-[#A083FC] font-black text-5xl md:text-7xl text-center text-transparent w-fit mx-auto bg-clip-text">
                  Get the Financial Support You Need—Fast!
                </h1>
                <p className="text-white text-center text-lg md:text-2xl mt-10">
                  Apply online in just a few steps and get approved instantly
                </p>
                <img
                  src={doubleEllipse}
                  className="hidden md:block absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
                  alt=""
                />
                <img
                  src={doubleArrow}
                  className="absolute bottom-0 right-0"
                  alt=""
                />
              </div>
            </div>
          </div>
          <div className="bg-gradient-to-r from-[#FFFFFF1A] via-[#7247FF] to-[#FFFFFF1A] h-px"></div>
          <div className="w-4/5 mx-auto py-36">
            <div>
              <div className="md:w-1/2 mx-auto">
                <h2 className="bg-gradient-to-r from-[#FFFFFF] to-[#A083FC] font-black text-3xl md:text-5xl text-center text-transparent w-fit mx-auto bg-clip-text">
                  Upfrontrent's Unique Offering
                </h2>
              </div>
              <div className="mt-10 md:mt-20 grid lg:grid-cols-2 gap-6">
                <div className="bg-gradient-to-tr from-[#FFFFFF26] to-[#7247FF4D] py-6 sm:py-14 px-6 sm:px-12 lg:h-[360px] rounded-xl">
                  <h3 className="text-[#7247FF] text-2xl md:text-4xl">1</h3>
                  <h4 className="text-white font-black text-xl md:text-3xl w-2/3 mt-8">
                    Uncollateralized Loans
                  </h4>
                  <p className="text-white text-xs md:text-sm md:w-3/4 mt-8">
                    Upfrontrent's offers affordable and uncollateralized loans,
                    making it easier for property owners to access quick funds
                    seamlessly.
                  </p>
                </div>
                <div className="bg-gradient-to-tr from-[#FFFFFF26] to-[#7247FF4D] py-6 sm:py-14 px-6 sm:px-12 lg:h-[360px] rounded-xl">
                  <h3 className="text-[#7247FF] text-2xl md:text-4xl">1</h3>
                  <h4 className="text-white font-black text-xl md:text-3xl w-2/3 mt-8">
                    Uncollateralized Loans
                  </h4>
                  <p className="text-white text-xs md:text-sm w-3/4 mt-8">
                    Upfrontrent's offers affordable and uncollateralized loans,
                    making it easier for property owners to access quick funds
                    seamlessly.
                  </p>
                </div>
                <div className="lg:h-[360px] rounded-xl">
                  <img src={uo1} className="h-full w-full" alt="" />
                </div>
                <div className="bg-gradient-to-tr from-[#FFFFFF26] to-[#7247FF4D] py-6 sm:py-14 px-6 sm:px-12 lg:h-[360px] rounded-xl">
                  <h3 className="text-[#7247FF] text-2xl md:text-4xl">1</h3>
                  <h4 className="text-white font-black text-xl md:text-3xl w-2/3 mt-8">
                    Uncollateralized Loans
                  </h4>
                  <p className="text-white text-xs md:text-sm w-3/4 mt-8">
                    Upfrontrent's offers affordable and uncollateralized loans,
                    making it easier for property owners to access quick funds
                    seamlessly.
                  </p>
                </div>
                <div className="bg-gradient-to-tr from-[#FFFFFF26] to-[#7247FF4D] py-6 sm:py-14 px-6 sm:px-12 lg:h-[360px] rounded-xl">
                  <h3 className="text-[#7247FF] text-2xl md:text-4xl">1</h3>
                  <h4 className="text-white font-black text-xl md:text-3xl w-2/3 mt-8">
                    Uncollateralized Loans
                  </h4>
                  <p className="text-white text-xs md:text-sm w-3/4 mt-8">
                    Upfrontrent's offers affordable and uncollateralized loans,
                    making it easier for property owners to access quick funds
                    seamlessly.
                  </p>
                </div>
                <div className="lg:h-[360px] rounded-xl">
                  <img src={uo2} className="h-full w-full" alt="" />
                </div>
              </div>
            </div>
            <div className="mt-20 md:mt-60">
              <div className="md:w-1/2 mx-auto">
                <h2 className="bg-gradient-to-r from-[#FFFFFF] to-[#A083FC] font-black text-3xl md:text-5xl text-center text-transparent w-fit mx-auto bg-clip-text">
                  Operational Excellence
                </h2>
              </div>
              <div className="mt-10 md:mt-20 grid lg:grid-cols-4 gap-6 relative z-10 lg:w-3/4 mx-auto overflow-hidden">
                <div className="bg-gradient-to-tr from-[#FFFFFF26] to-[#7247FF4D] py-6 sm:py-14 px-6 sm:px-12 lg:h-[360px] rounded-xl lg:col-span-2 z-20 relative">
                  <h3 className="text-[#7247FF] text-2xl md:text-4xl">1</h3>
                  <h4 className="text-white font-black text-xl md:text-3xl w-4/5 mt-8">
                    Digital Platform
                  </h4>
                  <p className="text-white text-xs md:text-sm w-11/12 mt-8">
                    Upfrontrent's secure and scalable digital platform is
                    designed for efficient application processing and loan
                    management.
                  </p>
                </div>
                <div className="bg-gradient-to-tr from-[#FFFFFF26] to-[#7247FF4D] py-6 sm:py-14 px-6 sm:px-12 lg:h-[360px] rounded-xl lg:col-span-2 z-20 relative">
                  <h3 className="text-[#7247FF] text-2xl md:text-4xl">1</h3>
                  <h4 className="text-white font-black text-xl md:text-3xl w-4/5 mt-8">
                    Uncollateralized Loans
                  </h4>
                  <p className="text-white text-xs md:text-sm w-11/12 mt-8">
                    Upfrontrent's offers affordable and uncollateralized loans,
                    making it easier for property owners to access quick funds
                    seamlessly.
                  </p>
                </div>
                <div className="bg-gradient-to-tr from-[#FFFFFF26] to-[#7247FF4D] py-6 sm:py-14 px-6 sm:px-12 lg:h-[360px] rounded-xl lg:col-span-2 z-20 relative lg:col-start-2">
                  <h3 className="text-[#7247FF] text-2xl md:text-4xl">1</h3>
                  <h4 className="text-white font-black text-xl md:text-3xl w-4/5 mt-8">
                    Digital Platform
                  </h4>
                  <p className="text-white text-xs md:text-sm w-11/12 mt-8">
                    Upfrontrent's secure and scalable digital platform is
                    designed for efficient application processing and loan
                    management.
                  </p>
                </div>
                <img
                  src={doubleEllipse}
                  className="hidden md:block absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 -z-10"
                  alt=""
                />
                <img
                  src={blur}
                  className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 -z-10"
                  alt=""
                />
              </div>
            </div>
            <div className="mt-20 md:mt-60" id="about">
              <div className="md:w-1/2 mx-auto">
                <h2 className="bg-gradient-to-r from-[#FFFFFF] to-[#A083FC] font-black text-3xl md:text-5xl text-center text-transparent w-fit mx-auto bg-clip-text">
                  About us
                </h2>
              </div>
              <div className="mt-10 md:mt-20 grid gap-6 md:gap-10 md:w-3/4 mx-auto">
                <div className="rounded-xl">
                  <img src={about1} className="w-full" alt="" />
                </div>
                <div className="md:w-2/3 text-center mx-auto">
                  <p className="text-white leading-7">
                    UPFRONTRENT is an innovative Financial Technology platform
                    business, that majors in delivering fast and affordable
                    loans to property owners with a proven stable rental income.{" "}
                  </p>
                  <p className="text-white leading-7 mt-8">
                    We deliver flexible and tailored credit solutions that eets
                    our customers needs, thus helping property owners bridge
                    liquidity gaps to meet rgent and essential property-related
                    xpenses, such as maintenance, renovations, and/or equity
                    contributions.
                  </p>
                </div>
              </div>
              <div className="mt-10 md:mt-20 grid gap-6 md:gap-10 md:w-3/4 mx-auto">
                <div className="rounded-xl">
                  <img src={about2} className="w-full" alt="" />
                </div>
                <div className="md:w-2/3 text-center mx-auto">
                  <p className="text-white leading-7">
                    UPFRONTRENT was incorporated in Nigeria in 2022 to drive
                    digital lending as a Fintech business in Nigeria, with a
                    5-year business expansion plan into other developed markets
                    ( such as Canada, USA Europe)
                  </p>
                </div>
              </div>
            </div>
            <div className="mt-20 md:mt-60 grid gap-8 md:gap-32">
              <div className="grid sm:grid-cols-3 items-center gap-8 md:gap-32">
                <div className="w-[150px] md:w-[250px]">
                  <h2 className="bg-gradient-to-r from-[#FFFFFF] to-[#A083FC] font-black text-3xl md:text-5xl text-center text-transparent w-fit bg-clip-text">
                    Vision
                  </h2>
                </div>
                <img src={arrowRight} alt="" />
                <p className="text-white leading-7">
                  To empower property owners to succeed in the real estate
                  investments.
                </p>
              </div>
              <div className="grid sm:grid-cols-3 items-center gap-8 md:gap-32">
                <div className="w-[150px] md:w-[250px]">
                  <h2 className="bg-gradient-to-r from-[#FFFFFF] to-[#A083FC] font-black text-3xl md:text-5xl text-center text-transparent w-fit bg-clip-text">
                    Mission
                  </h2>
                </div>
                <img src={arrowRight} alt="" />
                <p className="text-white leading-7">
                  To keep delivering innovative financial/ credit products for
                  all cadres of property owners and be a catalyst for continued
                  strategic growth.
                </p>
              </div>
              <div className="grid sm:grid-cols-3 items-center gap-8 md:gap-32">
                <div className="w-[150px] md:w-[250px]">
                  <h2 className="bg-gradient-to-r from-[#FFFFFF] to-[#A083FC] font-black text-3xl md:text-5xl text-center text-transparent w-fit bg-clip-text">
                    Purpose
                  </h2>
                </div>
                <img src={arrowRight} alt="" />
                <p className="text-white leading-7">
                  Eliminate financial gaps and promoted economy prosperity for
                  existing and prospective property owners.
                </p>
              </div>
            </div>
            <div className="mt-20 md:mt-60">
              <div className="md:w-1/2 mx-auto">
                <h2 className="bg-gradient-to-r from-[#FFFFFF] to-[#A083FC] font-black text-3xl md:text-5xl text-center text-transparent w-fit mx-auto bg-clip-text">
                  Market Insight: Canadian Rental Market
                </h2>
              </div>
              <div className="mt-10 md:mt-20 grid lg:grid-cols-12 gap-6">
                <div className="bg-gradient-to-tr from-[#FFFFFF26] to-[#7247FF4D] py-6 sm:py-14 px-6 sm:px-12 lg:h-[360px] rounded-xl lg:col-span-7 relative">
                  <div className="shadow-[0_0_20px_0_] shadow-[#7247FF] w-14 h-14 bg-white rounded-full"></div>
                  <h4 className="text-white font-black text-xl md:text-3xl w-4/5 mt-8">
                    Market Overview
                  </h4>
                  <p className="text-white text-xs md:text-sm w-11/12 mt-8">
                    The Canadian rental market is robust, with over 30% of
                    households renting their homes. The demand for rental
                    properties remains strong due to factors like urbanization,
                    immigration, and housing affordability chatlenges.
                  </p>
                  <img
                    src={netWhite}
                    className="absolute left-0 top-0 h-full"
                    alt=""
                  />
                </div>
                <div className="bg-gradient-to-tr from-[#FFFFFF26] to-[#7247FF4D] py-6 sm:py-14 px-6 sm:px-12 lg:h-[360px] rounded-xl lg:col-span-5 relative">
                  <div className="shadow-[0_0_20px_0_] shadow-[#7247FF] w-14 h-14 bg-white rounded-full"></div>
                  <h4 className="text-white font-black text-xl md:text-3xl w-4/5 mt-8">
                    Target Market
                  </h4>
                  <p className="text-white text-xs md:text-sm w-11/12 mt-8">
                    The Canadian rental market is robust, with over 30% of
                    households renting their homes. The demand for rental
                    properties remains strong due to factors like urbanization,
                    immigration, and housing affordability chatlenges.
                  </p>
                  <img
                    src={netWhite}
                    className="absolute left-0 top-0 h-full"
                    alt=""
                  />
                </div>
                <div className="bg-gradient-to-tr from-[#FFFFFF26] to-[#7247FF4D] py-6 sm:py-14 px-6 sm:px-12 lg:h-[360px] rounded-xl lg:col-span-12 relative">
                  <div className="shadow-[0_0_20px_0_] shadow-[#7247FF] w-14 h-14 bg-white rounded-full"></div>
                  <h4 className="text-white font-black text-xl md:text-3xl lg:w-1/2 lg:ml-auto mt-8">
                    Market Opportunity
                  </h4>
                  <p className="text-white text-xs md:text-sm lg:w-1/2 lg:ml-auto mt-8">
                    There is a growing demand for flexible financial solutions
                    in the rental market, as traditional banks often require
                    collateral and have lengthy approval processes, making it
                    difficult for property owners to access quick funds.
                  </p>
                  <p className="text-white text-xs md:text-sm lg:w-1/2 lg:ml-auto mt-4">
                    UPFRONTRENT addresses this gap by providing a fast,
                    uncollateralized loan solutions tailored to meet the needs
                    of landlords.
                  </p>
                  <img
                    src={netWhite}
                    className="absolute left-0 top-0 h-full"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Footer */}
        <div className="w-11/12 md:w-4/5 mx-auto my-12">
          <div className="grid lg:grid-cols-2">
            <div className="relative py-40 px-5">
              <h1 className="font-black text-7xl w-[300px]">Reach out to us</h1>
              <img src={net} className="absolute left-0 top-0 h-full" alt="" />
            </div>
            <div className="py-20 grid gap-10" id="contact">
              <div class="input-container mx-auto lg:mr-0">
                <input required type="text" />
                <label>Name</label>
              </div>

              <div class="input-container mx-auto lg:mr-0">
                <input required type="text" />
                <label>Email</label>
              </div>

              <div class="input-container mx-auto lg:mr-0">
                <input required type="text" />
                <label>Message</label>
              </div>
              <div className="input-container mx-auto lg:mr-0">
                <input type="submit" value="Message" />
              </div>
            </div>
          </div>
          <div className="lg:mt-32 grid grid-cols-3 gap-3 items-center">
            <img src={logo} alt="" />
            <p className="text-xs md:text-base">&copy; upfrontrent, 2024</p>
            <div className="grid gap-2 text-center md:grid-cols-3">
              <p className="text-xs md:text-base">Linkedin</p>
              <p className="text-xs md:text-base">X</p>
              <p className="text-xs md:text-base">Facebook</p>
            </div>
          </div>
        </div>
      </BrowserRouter>
    </>
  );
}
export default Home;
